<template>
  <div>
    <!-- search -->
    <div class="language-filter w-100 d-flex">
      <input type="text" @click="shakeReset" @input="onChange" v-model="search" @keyup.down="onArrowDown" @keyup.up="onArrowUp" @keyup.enter="onEnter" :placeholder="$t('start_typing_country')"/>
      <div class="btn px-4 btn-outline btn-sm" @click="addLanguage(search)" :class="shakeAnimation ? 'shake' : ''">{{ $t('add_button') }}</div>
      <ul id="language-filter-results" v-show="isOpen" class="language-filter-results">
        <li v-for="(result, i) in results" :key="i" @click="setResult(result)" class="language-filter-result" :class="{ 'is-active': i === arrowCounter }">
          {{ result }}
        </li>
      </ul>
    </div>
    <!-- selected -->
    <div class="language-filter-selected">
      <ul class="p-0 m-0 d-flex flex-row flex-wrap">
        <transition-group name="component-fade" mode="out-in">
          <li class="d-flex align-items-center" v-for="(input, index) in selectedCountryPills" :key="index + 0">
            {{ input.countryName }}
            <span @click="deleteRow(input.countryCode)"><i class="fa-solid fa-xmark ml-1" aria-hidden="true"></i></span>
          </li>
        </transition-group>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageFilter',
  props: {
    countryList: {
      type: Array,
      required: false,
      default: () => []
    },
    fullList: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedLanguages: {
      type: Array,
      required: false,
      default: () => []
    },
    onload: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: false,
      results: [],
      search: '',
      arrowCounter: 0,
      selectedCountryPills: this.selectedLanguages,
      selectedCountryArray: [],
      countryCode: '',
      shakeAnimation: false,
      invalidCountry: false
    }
  },
  methods: {
    onChange () {
      this.$emit('input', this.search)
      this.filterResults()
      this.isOpen = true
    },
    filterResults () {
      this.results = this.countryList.filter(item => {
        return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    shakeReset () {
      this.shakeAnimation = false
    },
    setResult (result) {
      this.search = result
      this.isOpen = false
    },
    addLanguage (search) {
      this.invalidCountry = false
      this.shakeAnimation = false
      if (search === null || search === '') {
        this.search = ''
        this.shakeAnimation = true
        return
      }
      // find Country code
      this.fullList.forEach((item) => {
        if (item.name === search) {
          this.countryCode = item.code
        }
      })

      // check not already in array
      if (this.selectedCountryArray.length > 0) {
        if (this.selectedCountryArray.includes(this.countryCode)) {
          this.search = ''
          this.countryCode = ''
          this.shakeAnimation = true
          return
        }
      }

      if (this.countryCode) {
        this.selectedCountryArray.push(this.countryCode)
        // create pill elements
        this.selectedCountryPills.push({
          countryName: search,
          countryCode: this.countryCode
        })
        // push and reset
        this.search = ''
        this.$emit('langChange', this.selectedCountryArray, this.selectedCountryPills)
        this.countryCode = ''
      } else {
        this.search = ''
        this.shakeAnimation = true
      }
    },
    deleteRow (country) {
      const index = this.selectedCountryArray.indexOf(country)
      if (index > -1) {
        this.selectedCountryArray.splice(index, 1)
      }
      this.$emit('langChange', this.selectedCountryArray)
      this.selectedCountryPills.splice(index, 1)
    },
    onArrowDown (evt) {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter () {
      this.search = this.results[this.arrowCounter]
      this.isOpen = false
      this.arrowCounter = -1
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    },
    clearAll () {
      this.selectedCountryArray = []
      this.selectedCountryPills = []
      this.search = ''
    },
    onloadFunction () {
      this.fullList.forEach((item) => {
        if (this.onload.includes(item.code)) {
          this.selectedCountryPills.push({
            countryName: item.name,
            countryCode: item.code
          })
          this.selectedCountryArray.push(
            item.code
          )
        }
      })
    }
  },
  watch: {
    countryList: function (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.results = val
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    this.onloadFunction()
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style lang="scss" scoped>

.language-filter {
  position: relative;
  height: 30px;
  font-size: 0.875rem;
  input {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: $brand-denim;
    border: 1px solid $input-grey;
    flex: 1;
    width: 70%;
  }
  .btn {
    outline: none !important;
    background-color: transparent !important;
    border: 1px solid $brand-sky;
    color: $brand-sky;
    height: 30px;
    margin-left: 10px;
    width: 30%;
    &.shake {
      animation: shaker 1s;
    }
  }
}

@keyframes shaker {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.language-filter-results {
  padding: 0;
  margin: 0;
  box-shadow: 0 0 0 1px $background-grey;
  height: auto;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  position: absolute;
  top: 31px;
  z-index: 9999;
  background: white;
  font-size: 0.875rem;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  .language-filter-result {
    list-style: none;
    text-align: left;
    padding: 4px 10px;
    cursor: pointer;
    &.is-active, &:hover {
      background-color: $brand-sky;
      color: white;
    }
  }
}

.language-filter-selected {
  margin-top: 10px;
  ul {
    span {
      display: flex;
      flex-wrap: wrap;
      li {
        background: $brand-sky;
        color: white;
        font-size: .75rem;
        padding: 3px 8px;
        border-radius: 10px;
        margin-right: 7px;
        margin-bottom: 7px;
        i {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.clear {
  right: 20px;
  top: 20px;
  font-size: 10px;
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .5s ease-in;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}

</style>
