<template>
  <div id="search-filter" class="filter-bar" :class="{ 'hideSidebar' : !display }">
    <!--mobile CTA -->
    <div class="mobile-cta position-fixed d-flex d-lg-none py-3 px-4">
      <b-button size="sm" variant="primary" class="w-100" @click="displayModal(false)">{{ $t('filter_apply') }}</b-button>
    </div>
    <div class="overflow">
      <!--mobile header -->
      <div class="d-flex d-lg-none w-100 mobile-header">
        <div class="px-4 py-3 d-flex flex-row justify-content-between w-100">
          <div @click="displayModal(false)" class="clickable d-flex align-items-center">
            <i class="fa-solid fa-chevron-left mr-2" aria-hidden="true"></i>
            {{ $t('back') }}
          </div>
          <div class="clickable" @click="clear('all')">{{ $t('filter_clear_all') }}</div>
        </div>
      </div>
      <!-- Search filter bar -->
      <div class="scroll-container px-2 px-lg-0 position-relative">
        <form id="filter-bar-form" class="d-flex flex-column flex-lg-row">
          <!-- price range -->
          <b-form-group id="price-range" class="form-group p-2 p-md-3 mb-0">
            <h5 class="mb-3 text-left">{{ $t('sidebar_subheader_price_range') }}</h5>
            <div class="form-inline">
              <div class="d-flex flex-row align-items-center justify-content-start mb-0">
                <b-input-group size="sm" class="price">
                  <b-input min="0" v-model="price.from" placeholder="$" name="price_from" @keypress.enter="submit('setRange', true)" />
                </b-input-group>
                <span class="mx-2 small">{{ $t('to') }}</span>
                <b-input-group size="sm" class="price">
                  <b-input min="0" v-model="price.to" placeholder="$" name="price_to" @keypress.enter="submit('setRange', true)" />
                </b-input-group>
                <b-button variant="outline" size="sm" class="px-2 price-btn" @click="submit('setRange', true)">
                  {{ $t('set_range') }}
                </b-button>
              </div>
              <div>
                <b-form-radio-group buttons class="d-flex flex-row" name="price" v-model="price.selected">
                  <div v-for="(option) in price.options" :key="option.id" class="filter-item d-flex" @change="submit('setOption', true)">
                    <b-form-radio :value="option.id">
                      {{ option.text }}
                    </b-form-radio>
                  </div>
                </b-form-radio-group>
              </div>
            </div>
          </b-form-group>

          <!-- delvivery time -->
          <b-form-group id="delivery-time" class="form-group p-2 p-md-3 mb-0">
            <h5 class="mb-3 text-left">{{ $t('sidebar_subheader_delivery_time') }}</h5>
            <b-form-radio-group buttons class="my-2 d-flex flex-row" name="delivery" v-model="deliveryTime.selected">
              <div v-for="(option) in deliveryTime.options" :key="option.id" class="filter-item d-flex" @change="submit">
                <b-form-radio :value="option.value">
                  {{ option.text }}
                </b-form-radio>
              </div>
            </b-form-radio-group>
          </b-form-group>

          <!-- star rating-->
          <b-form-group id="star-rating" class="p-2 p-md-3 mb-0">
            <h5 class="mb-3 text-left">{{ $t('rating_title') }}</h5>
            <b-form-radio-group buttons class="mt-2 d-flex flex-row" id="star-rating-group" name="rating" v-model="starRating.selected">
              <div v-for="option in starRating.options.slice()" :key="option.id" class="filter-item" @change="submit">
                <b-form-radio :value="option.value">
                  <div class="d-flex flex-row align-items-center">
                    <span class="d-flex value mr-1">{{option.text}}</span>
                    <b-form-rating class="rating-readonly" v-model="option.value" readonly precision="0" />
                  </div>
                </b-form-radio>
              </div>
            </b-form-radio-group>
          </b-form-group>

          <!-- language -->
          <b-form-group id="language" class="p-2 p-md-3 pb-sm-2 position-relative">
            <h5 class="mb-3 text-left flex-nowrap">{{ $t('sidebar_subheader_seller_language') }}</h5>
            <div v-if="languages.options.length > 0">
              <LanguageFilter :countryList="countryListArr" :fullList="languages.options" :onload="this.languages.selected" :selectedLanguages="this.languages.allLanguages" v-on:langChange = "langChange"/>
            </div>
            <div v-else>
              <b-spinner class="mt-5 mb-2 mb-md-4" small label="Loading..."></b-spinner>
            </div>
          </b-form-group>
          <div class="bg-white position-absolute reset-filters d-none d-lg-flex align-items-center" @click="clear('all')">
            {{ $t('clear_all') }}
            <i class="fa-regular fa-trash-can ml-2" aria-hidden="true"></i>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from '@/apiService'
import LanguageFilter from './LanguageFilter'

export default {
  name: 'TaskFilter',
  components: {
    LanguageFilter
  },
  created () {
    this.getAllLanguages()
  },
  data () {
    return {
      price: {
        from: '',
        to: '',
        selected: 0,
        options: [
          { id: 0, text: this.$t('filter_option_all'), value: ['', ''] },
          { id: 1, text: this.$t('filter_price_under') + ' $5', value: ['0', '5'] },
          { id: 2, text: '$5 - $10', value: ['5', '10'] },
          { id: 3, text: '$10 - $25', value: ['10', '25'] },
          { id: 4, text: '$25 - $50', value: ['25', '50'] },
          { id: 5, text: '$50+', value: ['50', ''] }
        ]
      },
      deliveryTime: {
        selected: 0,
        options: [
          { id: 0, text: this.$t('filter_delivery_option_any'), value: '' },
          { id: 1, text: this.$t('filter_delivery_option_24_hours'), value: '24' },
          { id: 2, text: this.$t('filter_delivery_option_3_days'), value: '72' },
          { id: 3, text: this.$t('filter_delivery_option_7_days'), value: '168' }
        ]
      },
      starRating: {
        selected: 0,
        options: [
          { id: 5, value: '5', text: '5' },
          { id: 4, value: '4', text: '4+' },
          { id: 3, value: '3', text: '3+' },
          { id: 2, value: '2', text: '2+' },
          { id: 1, value: '1', text: '1+' },
          { id: 0, value: '0', text: this.$t('filter_option_all') }
        ]
      },
      countryListArr: [],
      languages: {
        selected: [],
        options: [],
        allLanguages: []
      },
      toggle: {
        current: 'hide',
        hidden: 'hidden',
        word: {
          hide: '+ ' + this.$t('dropdown_show_more'),
          show: '- ' + this.$t('dropdown_hide')
        }
      },
      display: false
    }
  },
  mounted () {
    this.rebuildFilter()
    this.previous = this.getData(false)
  },
  methods: {
    rebuildFilter () {
      let params = this.$route.query

      this.price.from = (params.price_from) ? params.price_from : ''
      this.price.to = (params.price_to) ? params.price_to : ''
      if (this.price.from !== '' && this.price.to !== '') {
        this.price.options.forEach((item) => {
          if (item.value[0] === params.price_from && item.value[1] === params.price_to) {
            this.price.selected = item.id
          }
        })
      }
      this.deliveryTime.selected = (params.delivery) ? params.delivery : ''
      this.starRating.selected = (params.rating) ? params.rating : ''
      this.languages.selected = (params.languages && typeof params.languages === 'string') ? params.languages.split(',') : []
    },
    getData (reload = false, resetSubmittedData = false) {
      if (reload) {
        this.rebuildFilter()
      }
      return {
        'deliveryTime': this.deliveryTime.selected,
        'price': this.price.selected,
        'min': this.price.from,
        'max': this.price.to,
        'languages': this.languages.selected,
        'rating': this.starRating.selected
      }
    },
    submit (from, updatingPrice = false) {
      if (updatingPrice) {
        if (from === 'setRange') {
          this.price.selected = null
        } else if (from === 'setOption') {
          this.price.from = this.price.options[this.price.selected].value[0]
          this.price.to = this.price.options[this.price.selected].value[1]
        }
      }

      let params = this.$route.query
      let formData = [
        ['price_from', this.price.from],
        ['price_to', this.price.to],
        ['delivery', this.deliveryTime.selected],
        ['rating', this.starRating.selected],
        ['languages', this.languages.selected.length > 0 ? this.languages.selected.join(',') : ''],
        ['page', 1]
      ].reduce((ac, val) => ({ ...ac, [val[0]]: val[1] }), {})

      this.$router.push({ query: Object.fromEntries(Object.entries({ ...params, ...formData }).filter(([_, v]) => v !== '')) }).catch(() => {})
    },
    clear (type) {
      switch (type) {
        case 'price':
          this.price.to = ''
          this.price.from = ''
          break
        case 'deliveryTime':
          this.deliveryTime.selected = ''
          break
        case 'starRating':
          this.starRating.selected = ''
          break
        case 'languages':
          this.languages.selected = []
          break
        case 'all':
          this.price.to = ''
          this.price.from = ''
          this.price.selected = 0
          this.deliveryTime.selected = ''
          this.starRating.selected = ''
          this.languages.selected = []
          this.$children[3].$children[0].clearAll()
          break
      }
      this.submit()
    },
    displayModal (action) {
      if (action === true) {
        document.body.classList.add('model-open')
      } else {
        document.body.classList.remove('model-open')
        this.filterBarVisible = !this.filterBarVisible
      }
      this.display = action
    },
    getAllLanguages () {
      return ApiService.getAllLanguages('task_count.desc').then(response => {
        this.languages.options = response.data.data
        var vm = this
        this.languages.options.forEach(function (item) {
          vm.countryListArr.push(item.name)
        })
      })
    },
    langChange (data, allLanguages) {
      this.languages.selected = data
      this.languages.allLanguages = allLanguages
      this.submit()
    }
  }
}
</script>

<style scoped>
.clear-input {
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}
.hidden {
  display: none;
}
</style>

<style lang="scss">

h5 {
  font-family: $header-font;
  font-weight: bold;
}
.mobile-header {
  background: $background-grey;
  border-bottom: 1px solid $task-grey-border;
  .clickable {
    &:hover {
      cursor: pointer;
    }
  }
}
.mobile-cta {
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 99;
}

.filter-bar {
  border: none;
  padding: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 9999;
  .reset-filters {
    right: 15px;
    top: 13px;
    font-size: 14px;
    border: 1px solid #ced4da;
    padding: 4px 5px;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
    }
    .fa-regular {
      font-size: 13px;
    }
  }
  .form-group {
    border-right: 0;
    width: 100%;
    .price {
      max-width: 20%;
    }
  }
  .filter-item {
    margin: 0 10px 10px 0;
    &:last-of-type {
      margin: 0;
    }
    .btn.btn-secondary {
      padding: 4px 7px;
      font-size: 14px;
      line-height: 20px;
      display: block;
      border: 1px solid $task-grey-border;
      border-radius: 6px;
      transition: .25s all;
      margin: 0;
      background: transparent;
      color: $brand-navy;
      max-height: 30px;
      input[type="radio"], input[type="checkbox"] {
        display: none;
      }
      &::before {
        display: none;
      }
      &:hover, &.active {
        transition: .25s all;
        cursor: pointer;
        border: 1px solid $brand-sky;
        background: transparent;
        color: $brand-navy;
      }
    }
  }
}

#price-range {
  .price-btn {
    outline: none !important;
    background-color: transparent !important;
    border: 1px solid $brand-sky;
    color: $brand-sky;
    height: 30px;
    margin-left: 10px;
  }
  .btn-group {
    margin-top: 10px;
  }
  .form-control {
    height: 30px;
  }
  .filter-item {
    &:nth-child(5), &:nth-child(6) {
      margin: 0 10px 0 0;
    }
  }
}

#star-rating {
  .custom-control-label {
    display: inline-flex;
    .value {
      font-size: 12px;
    }
    .b-icon.bi {
      width: 14px;
    }
  }
  .filter-item {
    &:last-of-type {
      .b-rating {
        display: none !important;
      }
    }
    .rating-readonly {
      padding: 0;
      border: 0;
      height: 16px;
      flex: 1;
      outline: 0;
      &:active, &:focus {
        box-shadow: none;
      }
      .b-rating-star {
        padding: 0 .1rem;
        color: $star-yellow;
        .b-rating-icon {
          svg {
            font-size: 12px;
          }
        }
      }
    }
  }
}

#language {
  margin-bottom: 185px;
}

@include lg-up {
  .scroll-container {
    height: auto;
    width: 100%;
  }
  .filter-bar {
    z-index: 4;
    height: auto;
    position: relative;
    border: 1px solid $task-grey-border;
    background-color: white;
    // margin-top: 8px;
    border-radius: 0.5rem !important;
    .form-group {
      border-right: 1px solid $task-grey-border;
      border-bottom: 0;
      width: 25%;
      &:last-of-type {
        border: 0;
      }
    }
  }
  #language {
    margin-bottom: 0;
  }

}

@media (min-width:900px) and (max-width:1140px) {
  .filter-item {
    span {
      font-size: 10px;
    }
    .b-rating-star {
      .b-rating-icon {
        svg {
          font-size: 8px !important;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .hideSidebar {
    display: none;
  }
}

</style>
