import { render, staticRenderFns } from "./TaskFilter.vue?vue&type=template&id=b98df012&scoped=true&"
import script from "./TaskFilter.vue?vue&type=script&lang=js&"
export * from "./TaskFilter.vue?vue&type=script&lang=js&"
import style0 from "./TaskFilter.vue?vue&type=style&index=0&id=b98df012&scoped=true&lang=css&"
import style1 from "./TaskFilter.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b98df012",
  null
  
)

export default component.exports